<template>
  <v-container>
    <timeseries-analytics
      class="mb-10"
      title="Nacks"
      _key="Nacks"
    />
    <div>
      <loader
        v-if="isLoading"
        message="Loading ...."
      />
      <div v-else>
        <s-text
          weight="bold"
          size="md-m"
          class="mb-4"
        >
          Messages Pending Delivery
        </s-text>
        <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
          <v-col class="d-flex flex-column py-0" cols="2">
            <s-text weight="medium" color="gray" size="sm">
              Message Type
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column py-0" cols="3">
            <s-text weight="medium" color="gray" size="sm">
              From
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column py-0" cols="3">
            <s-text weight="medium" color="gray" size="sm">
              To
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column py-0" cols="4">
            <s-text weight="medium" color="gray" size="sm">
              Date Sent
            </s-text>
          </v-col>
        </v-row>
        <v-row
          v-for="mail in mails"
          :key="mail.ID"
          class="pa-0 ma-0 mb-3 app-card app-card-content"
        >
          <v-col class="d-flex flex-column" cols="2">
            <div>
              {{ mail.envelope.type }}
            </div>
          </v-col>
          <v-col class="d-flex flex-column" cols="3">
            <s-link
              weight="medium"
              color="grayTextAlt"
              :to="{ name: 'appDetails', params: { id: mail.envelope.from } }"
            >
              {{ mail.envelope.from }}
            </s-link>
          </v-col>
          <v-col class="d-flex flex-column" cols="3">
            <s-link
              weight="medium"
              color="grayTextAlt"
              :to="{ name: 'appDetails', params: { id: mail.recipient } }"
            >
              {{ mail.recipient }}
            </s-link>
          </v-col>
          <v-col class="d-flex flex-column" cols="4">
            <div>
              {{ mail.created_at ? getHumanReadableDate(mail.created_at) : "-" }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDate } from '@/utils/date'
import TimeseriesAnalytics from '@/components/cards/TimeseriesAnalytics'
export default {
  name: 'Debug',
  components: {
    loader: Loader,
    'timeseries-analytics': TimeseriesAnalytics,
  },
  computed: {
    ...mapGetters({
      mails: 'mail/mails',
    }),
  },
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    getHumanReadableDate,
    getMails () {
      this.isLoading = true
      this.$store
        .dispatch('mail/getMails')
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  watch: {
    mails: {
      handler () {
        if (this.mails) return
        this.getMails()
      },
      immediate: true,
    },
  },
}
</script>
